@use "sass:math";
@use "sass:map";
@use "sass:color";
@import "../variables";

$question-image-vunits: 10, 20, 30, 40, 50, 60, 70, 80, 90, 100;

@function theme-get($key) {
  @return map.get($theme-map, $key);
}

@mixin theme() {
  @each $theme, $map in $themes {
    $theme-map: $map !global;

    .theme-#{$theme} & {
      @content;
    }
  }

  $theme-map: null !global;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map.get($map, $key);
  }

  @return $map;
}

@mixin image-widths {
  @each $question-image-vunit in $question-image-vunits {
    img.image-width-#{$question-image-vunit} {
      width: math.div(math.percentage($question-image-vunit), 100);
      max-width: ($max-image-viewport-width * math.div($question-image-vunit, 100)) + px;
      max-height: inherit;
    }

    img.image-height-#{$question-image-vunit} {
      height: #{$question-image-vunit}vh;
      max-width: inherit;
      max-height: inherit;
    }
  }

  @media (width >= 760px) {
    @each $question-image-vunit in $question-image-vunits {
      img.md-image-width-#{$question-image-vunit} {
        width: math.div(math.percentage($question-image-vunit), 100);
        max-width: inherit;
        max-height: inherit;
      }

      img.md-image-height-#{$question-image-vunit} {
        height: #{$question-image-vunit}vh;
        max-width: inherit;
        max-height: inherit;
      }
    }
  }

  @media (width >= 950px) {
    @each $question-image-vunit in $question-image-vunits {
      img.lg-image-width-#{$question-image-vunit} {
        width: math.div(math.percentage($question-image-vunit), 100);
        max-width: inherit;
        max-height: inherit;
      }

      img.lg-image-height-#{$question-image-vunit} {
        height: #{$question-image-vunit}vh;
        max-width: inherit;
        max-height: inherit;
      }
    }
  }
}

@mixin question-navigation {
  &[class*="kip-attempts-"] {
    font-weight: 600;
    background-color: $kip-red-100;
    color: $danger;
    border: 0;
  }

  &.kip-attempts-0 {
    color: $gray-900;
    border-color: $gray-300;
    background-color: $white;
    font-weight: 400;

    &:hover {
      @extend .alert-primary;

      border-color: $primary;
    }
  }

  &.kip-attempts-1 {
    border-color: #dcb453;
    color: #b24525;
    background-color: #fbecc9;
  }

  &.kip-correct {
    &.kip-attempts-1 {
      @extend .alert-success;
    }

    &.kip-attempts-2 {
      @extend .alert-warning;
    }
  }

  &.kip-visited {
    &.kip-attempts-0 {
      @extend .alert-secondary;
    }
  }
}

@mixin make-header-text {
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.1em;
}

@mixin make-session-text {
  font-size: 0.875rem;
}

@mixin margin-column-gutters() {
  [class*="col"] {
    margin: 0 (math.div($spacer, 2));
  }
}

@mixin make-detail-column($width, $offset: 0) {
  width: calc(#{$width} - #{$offset});
}

@mixin bootstrap-focus-style {
  border: 1px solid #3889d4;
  box-shadow: 0 0 0 0.2rem rgb(0 84 159 / 25%);
}

@mixin make-card-hover($hover-cover: #3889D4) {
  border: 1px solid $hover-cover;
  box-shadow: 0 2px 4px rgba($hover-cover, 0.25);
  cursor: pointer;
  transition:
    border 0.12s ease,
    box-shadow 0.12s ease;
}

@mixin make-student-chip($lesson-summary-width: 230px) {
  cursor: pointer;
  min-width: $lesson-summary-width;
  max-width: $lesson-summary-width;
  background: $light;
  border: 1px solid $border-color;
  box-sizing: border-box;
  box-shadow: 0 2px 4px $box-shadow;
  border-radius: 10px;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
}

@mixin make-custom-checkbox {
  .custom-control-input~.custom-control-label::before {
    width: 1.5em;
    height: 1.5em;
    margin-top: -0.25em;
    margin-left: -0.5em;
    border-radius: 0.5em;
    border-color: theme-get("primary");
  }

  .custom-control-input:checked~.custom-control-label::before {
    background: theme-get("gradient-solid");
    margin-left: -0.5em;
  }

  .custom-control-input:checked~.custom-control-label::after {
    width: 1.5em;
    height: 1.5em;
    margin-top: -0.25em;
    margin-left: -0.5em;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 1.39395C14 1.6752 13.875 1.9252 13.6875 2.1127L5.6875 10.1127C5.5 10.3002 5.25 10.394 5 10.394C4.71875 10.394 4.46875 10.3002 4.28125 10.1127L0.28125 6.1127C0.09375 5.9252 0 5.6752 0 5.39395C0 4.83145 0.4375 4.39395 1 4.39395C1.25 4.39395 1.5 4.51895 1.6875 4.70645L5 7.9877L12.2812 0.706451C12.4688 0.518951 12.7188 0.393951 13 0.393951C13.5312 0.393951 14 0.831451 14 1.39395Z' fill='white'/%3E%3C/svg%3E%0A");
  }
}

@each $color,
$value in $theme-colors {
  $theme-color: map.get($theme-colors, $color);

  @if map-has-key($alert-backgrounds, $color) {
    $alert-background: map.get($alert-backgrounds, $color);

    .alert-#{$color},
    .alert-#{$color}:focus {
      color: $theme-color;
      background-color: $alert-background;
      border-width: 1px;
      border-color: color.adjust($theme-color, $lightness: 50%);

      &:hover {
        border-color: $theme-color;
      }
    }
  }
}

// Create a variant of alerts, modified by alert--dark class. Used with displaying percentages.

@each $color,
$value in $theme-colors {
  $theme-color: map.get($theme-colors, $color);

  @if map-has-key($alert-dark-backgrounds, $color) {
    $alert-background: map.get($alert-dark-backgrounds, $color);

    .alert--dark.alert-#{$color} {
      color: $theme-color;
      background-color: $alert-background;
      border-width: 1px;
      border-color: color.adjust($theme-color, $lightness: 50%);

      &:hover {
        border-color: $theme-color;
      }
    }
  }
}
